.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.chat-container {
  width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.messages {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.message.user {
  background-color: #e6f7ff;
  text-align: right;
}

.message.ChatGPT {
  background-color: #f1f1f1;
}

.message .message-content {
  display: inline-block;
}

.message .message-timestamp {
  font-size: 0.8em;
  color: #888;
  margin-top: 5px;
}

.input-container {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.input-container input[type="text"] {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.input-container button {
  padding: 10px;
  background-color: #1976d2;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.input-container button:hover {
  background-color: #155a9c;
}

.input-container input[type="file"] {
  margin-left: 10px;
}
